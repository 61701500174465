import React from "react"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"
import _ from "lodash"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Content } from "../components/layouts"
import MenuReset from "../components/MenuReset"

export const BlogLayout = ({ content, tags, title, contentIsMarkdown }) => (
  <>
    <Content>
      <BlogPostContainer>
        <div>
          <h2 className="main-title">{title}</h2>
          {typeof content === "object" && (
            <pre>{JSON.stringify(content, null, 2)}</pre>
          )}
          {contentIsMarkdown ? (
            <ReactMarkdown source={content} />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: content }} />
          )}

          <h4>Tags</h4>
          <ul>
            {tags &&
              tags.map(t => (
                <li key={t}>
                  <Link to={`/tags/${_.kebabCase(t)}`}>{t}</Link>
                </li>
              ))}
          </ul>
        </div>
      </BlogPostContainer>
    </Content>
  </>
)

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  return (
    <Layout solidHeader>
      <SEO title={post.frontmatter.title} />
      <MenuReset />

      <BlogLayout
        title={post.frontmatter.title}
        content={post.html}
        tags={post.frontmatter.tags}
      />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        tags
      }
    }
  }
`

const BlogPostContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  > div {
    width: 100%;
    max-width: 620px;
    padding: 0 var(--globalPadding);
  }

  img {
    display: block;
    max-width: 100%;
  }

  .main-title {
    color: var(--blue);
    font-size: 2.5em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    strong {
      font-weight: normal;
    }
  }
`
